import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import { ScrollArea } from "./ui/scroll-area";
import list from "./lists.json";
import { Link, useSearchParams } from "react-router-dom";

function Trigger({ value, name }) {
  return (
    <TabsTrigger
      value={value}
      className="bg-[#F1F6F9] text-[min(3.8vw,28px)] leading-[2.85] text-[#758EA7] data-[state=active]:bg-transparent data-[state=active]:text-[#F1F6F9] ">
      <Link
        className="block"
        to={{
          pathname: "/",
          search: `?category=${value}`,
        }}>
        {name}
      </Link>
    </TabsTrigger>
  );
}

function Content({ value }) {
  return (
    <TabsContent value={value} className="overflow-hidden bg-white">
      <ScrollArea className="h-full">
        <ul>
          {list[value]?.map((product, index) => {
            return (
              <li
                key={index}
                className="flex justify-between items-start border-b border-b-[#0D4379] py-[4.8%] px-[2.4%]">
                <img
                  src={`/images/${value}/${product.number}.png`}
                  alt={product.title}
                  className="w-[25.6%] block"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/default.png";
                  }}
                />
                <div className="w-[71.8%] space-y-[3.5%]">
                  <div className="leading-none font-medium text-[#758EA7] text-[min(2.77vw,20px)]">
                    {product.englishTitle}
                  </div>
                  <div className="flex justify-between leading-none font-bold text-[#0D4379] text-[min(4.44vw,32px)] gap-4">
                    <div>{product.title}</div>
                    {product.price && (
                      <div className="flex-none">
                        {product.price.toLocaleString("ko-KR")}원
                      </div>
                    )}
                  </div>
                  <div className="text-[#758EA7] tracking-[-.01em] leading-[1.4] text-[min(3.88vw,28px)]">
                    {product.description}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </ScrollArea>
    </TabsContent>
  );
}

export default function Products() {
  const categories = [
    "ExclusiveIC",
    "BRIC",
    "GELATO",
    "CAKE",
    "COFFEE",
    "DESSERT",
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get('category')?.toUpperCase();
  const displayCategory = categories.includes(category)
    ? category
    : "EXCLUSIVEIC";

  return (
    <Tabs defaultValue={displayCategory} className="w-full h-full flex flex-col">
      <TabsList className="grid font-semibold grid-cols-3 gap-[1px] bg-[#0D4379] border-y border-y-[#0D4379]">
        <Trigger value="EXCLUSIVEIC" name="Exclusive IC"  />
        <Trigger value="BRIC" name="BR IC"  />
        <Trigger value="GELATO" name="GELATO" />
        <Trigger value="CAKE" name="CAKE" />
        <Trigger value="COFFEE" name="COFFEE" />
        <Trigger value="DESSERT" name="DESSERT" />
      </TabsList>
      <Content value="EXCLUSIVEIC" />
      <Content value="BRIC" />
      <Content value="GELATO" />
      <Content value="CAKE" />
      <Content value="COFFEE" />
      <Content value="DESSERT" />
    </Tabs>
  );
}
