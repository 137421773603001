import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./images/logo.svg";
import Products from "./Products";

function WorkShop() {
  return (
    <div className="bg-[url('./images/background.png')] bg-cover bg max-w-[720px] h-[100svh] mx-auto">
      <div className="h-full py-[8%] px-[5%] flex flex-col justify-between gap-[20px]">
        <h1>
          <img src={logo} alt="workshop by baskinrobbins" className="w-[46%] block mx-auto" />
        </h1>
        <div className="h-[91%] shrink-0 grow-0">
          <Products />
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WorkShop />} />
      </Routes>
    </BrowserRouter>
  )
}